import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';

import { LOCAL_STORAGE, STATUS_CODE } from '../core/constants';
import { environment } from 'src/environments/environment';
import { ApiResponse } from './interfaces/http.interface';

@Injectable({
	providedIn: 'root'
})
export class HttpService {
	[x: string]: any;

	constructor(private readonly http: HttpClient) { }

	private getHeaders = (isContentType: boolean = true): HttpHeaders => {
		const accessToken = localStorage.getItem('accessToken');

		const headers = new HttpHeaders({
			'authorization': accessToken ? 'bearer ' + accessToken : '',
		});

		if (isContentType) {
			headers.append('Content-Type', 'application/json')
		}


		return headers;
	}

	private appendParams(myParams: { [x: string]: any; }): HttpParams {
		let params = new HttpParams();
		if (!!myParams) {
			for (const key of Object.keys(myParams)) {
				params = params.append(key, myParams[key]);
			}
		}
		return params;
	}

	private errorHandler(error: HttpErrorResponse) {
		if (error.status == STATUS_CODE.UNAUTHORIZED) {
			localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN);
		}

		return throwError(error.error);
	}

	get(url: string, params?: any) {
		const apiUrl = `${environment.API_URL}${url}`;

		return this
			.http
			.get(apiUrl, { params: this.appendParams(params), headers: this.getHeaders() })
			.pipe(map((response) => response), catchError(this.errorHandler));
	}

	post(url: string, payload: any) {
		const apiUrl = `${environment.API_URL}${url}`;

		return this
			.http
			.post(apiUrl, payload, { headers: this.getHeaders(false) })
			.pipe(map((response) => response), catchError(this.errorHandler));
	}

	put(url: string, payload: any) {
		const apiUrl = `${environment.API_URL}${url}`;

		return this
			.http
			.put(apiUrl, payload, { headers: this.getHeaders() })
			.pipe(map((response) => response), catchError(this.errorHandler));
	}

	patch(url: string, payload: any) {
		const apiUrl = `${environment.API_URL}${url}`;

		return this
			.http
			.patch(apiUrl, payload, { headers: this.getHeaders() })
			.pipe(map((response) => response), catchError(this.errorHandler));
	}

	delete(url: string) {
		const apiUrl = `${environment.API_URL}${url}`;

		return this
			.http
			.delete(apiUrl, { headers: this.getHeaders() })
			.pipe(map((response) => response), catchError(this.errorHandler));
	}
}
