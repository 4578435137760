import { STATUS_CODE } from './statusCode';

const AUTH_TYPE = {
	LOGIN: 'LOGIN',
	SIGNUP: 'SIGNUP',
	ADD_DETAILS: 'ADD_DETAILS',
	FORGET_PASSWORD: 'FORGET_PASSWORD',
	VERIFY_OTP: 'VERIFY_OTP',
	NEW_PASSWORD: 'NEW_PASSWORD'
};

const LOCAL_STORAGE = {
	ACCESS_TOKEN: 'accessToken'
};

const THEME_MODE = {
	BLACK: 'BLACK',
	WHITE: 'WHITE',
};

const USER_TYPE = {
	PENDING: 'PENDING',
	DECLINED: 'DECLINED',
	APPROVED: 'APPROVED',
};

const currency = 'USD';

const RANGE_FILTER = [
	 "WEEK",
	"MONTH",
	"YEAR"
];

const PRICE_FILTER = [
	{
		VALUE:'1-100',
		TEXT:'1-100'
	},
	{
		VALUE:'200-500',
		TEXT:'200-500'
	},
	{
		VALUE:'500-1000',
		TEXT:'500-1000'
	}
];


export {
	AUTH_TYPE,
	STATUS_CODE,
	LOCAL_STORAGE,
	THEME_MODE,
	USER_TYPE,
	RANGE_FILTER,
	PRICE_FILTER
};