import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './modules/home/home.component';
import { AccessControlComponent } from './modules/access-control/access-control.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { LoaderModule } from './modules/loader/loader.module';
import { ModalsModule } from './modals/modals.module';


@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		SidebarComponent,
		AccessControlComponent,
		
	],
	imports: [
		ModalsModule,
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		NgxMatIntlTelInputComponent,
		MatSnackBarModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatFormFieldModule,
		BrowserAnimationsModule,
		HttpClientModule,
		NgOtpInputModule,
		LoaderModule
	],
	providers: [],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent]
})
export class AppModule { }
