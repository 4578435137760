<div class="side-bar-left">
    <div class="top-logo">
        <img src="assets/images/logo.png" />
    </div>
    <div class="menu-list">
        <span>Main</span>
        <ul>
            <li [ngClass]="{'active': activeUrl === '/dashboard'}">
                <a [routerLink]="['/dashboard']" routerLinkActive="active">
                    <img src="assets/images/left-one-hover.png" alt="" />
                    Dashboard
                </a>
            </li>
            <!-- <li>
                <a href="#">
                    <img src="assets/images/left-two.png" alt="" />
                    User Management
                </a>
            </li> -->
            <li [ngClass]="{'active': activeUrl.startsWith('/leads')}">
                <a [routerLink]="['/leads']">
                    <img src="assets/images/left-two.png" alt="" />
                    Lead Management
                </a>
            </li>
            <li [ngClass]="{'active': activeUrl.startsWith('/properties')}">
                <a [routerLink]="['/properties']">
                    <img src="assets/images/left-two.png" alt="" />
                    Property Management
                </a>
            </li>
            <!-- <li>
                <a href="#">
                    <img src="assets/images/left-two.png" alt="" />
                    Contact Management
                </a>
            </li> -->
            <li [ngClass]="{'active': activeUrl.startsWith('/calendar')}">
                <a [routerLink]="['/calendar']">
                    <img src="assets/images/left-two.png" alt="" />
                    Calendar and Task
                </a>
            </li>
            <!-- <li>
                <a href="#">
                    <img src="assets/images/left-two.png" alt="" />
                    Reserved Properties
                </a>
            </li> -->
            <!-- <li>
                <a href="#">
                    <img src="assets/images/left-two.png" alt="" />
                    Auctioned Properties
                </a>
            </li> -->
            <!-- <li>
                <a href="#">
                    <img src="assets/images/left-two.png" alt="" />
                    Contract Management
                </a>
            </li> -->
            <!-- <li>
                <a href="#">
                    <img src="assets/images/left-two.png" alt="" />
                    Commission Management
                </a>
            </li> -->
            <!-- <li>
                <a href="#">
                    <img src="assets/images/left-two.png" alt="" />
                    Payouts and logs
                </a>
            </li> -->
            <!-- <li>
                <a href="#">
                    <img src="assets/images/left-two.png" alt="" />
                    Reporting and Analytics
                </a>
            </li> -->
        </ul>
        <ul class="log-link">
            <li [ngClass]="{'active': activeUrl === '/profile'}">
                <a [routerLink]="['/profile']">
                    <img src="assets/images/left-two.png" alt="" />
                    Profile
                </a>
            </li>
            <li><a href="#"><img src="assets/images/left-two.png" alt="" />Notifications</a></li>
            <li class="logout" (click)="logoutAgent()">
                <img src="assets/images/left-two.png" alt="" class="me-1" />
                Logout
            </li>
        </ul>
    </div>

</div>