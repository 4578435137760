import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';

import { HttpService } from './http-service.service';
import {
	ExistingAgentPayload,
	OTPPayload,
	CreateAgentPayload,
	LoginAgentPayload,
	LoginUserResponse,
	ExistingAgentResponse,
	ResetPasswordPayload,
	VerifyOTPResponse,
	UserProfileResponse,
	Agent,
	UpdateProfilePayload
} from './interfaces/auth.interface';
import { THEME_MODE } from '../core/constants';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(private readonly httpService: HttpService) { }

	user: BehaviorSubject<Agent | null> = new BehaviorSubject<Agent | null>(null);
	theme: BehaviorSubject<string> = new BehaviorSubject<string>(THEME_MODE.WHITE);

	verifyPhone(payload: ExistingAgentPayload): Observable<ExistingAgentResponse> {
		return this.httpService.post('auth/verifyPhone', payload) as Observable<ExistingAgentResponse>;
	}

	verifyOTP(payload: OTPPayload) {
		return this.httpService.patch('auth/verifyOTP', payload) as Observable<VerifyOTPResponse>;
	}

	createAgent(payload: CreateAgentPayload) {
		return this.httpService.post('auth/signup', payload);
	}

	resetPassword(payload: ResetPasswordPayload) {
		return this.httpService.patch('auth/resetPassword', payload);
	}

	forgotPassword(payload: ExistingAgentPayload) {
		return this.httpService.post('auth/forgotPassword', payload) as Observable<ExistingAgentResponse>;
	}

	login(payload: LoginAgentPayload): Observable<LoginUserResponse> {
		return this.httpService.post('auth/login', payload) as Observable<LoginUserResponse>;
	}

	async profile() {
		const response = await firstValueFrom(this.httpService.get('auth/profile')) as UserProfileResponse;

		this.user.next(response.user);
	}

	updateProfile(payload: UpdateProfilePayload): Observable<UserProfileResponse> {
		return this.httpService.patch('auth/profile', payload) as Observable<UserProfileResponse>;
	}
}
