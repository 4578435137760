<div class="login-page">
    <div class="row">
        <div class="col-lg-6">
            <div class="login-right">
                <img src="assets/images/logo.png" alt="" />
                <h3>Welcome Back!</h3>
                <p>Enter your registered email and password to manage your Dashboard, track your Leads, and enjoy
                    benefits.</p>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="login-left">
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link"
                            [ngClass]="authType === 'SIGNUP' || authType === 'ADD_DETAILS' ? 'active': ''"
                            id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button"
                            role="tab" aria-controls="pills-home" aria-selected="true"
                            (click)="handleAuthChange('SIGNUP')">
                            Signup
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" [ngClass]="authType === 'LOGIN' ? 'active': ''" id="pills-profile-tab"
                            data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab"
                            aria-controls="pills-profile" aria-selected="false" (click)="handleAuthChange('LOGIN')">
                            Signin
                        </button>
                    </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                    <!-- SIGNUP -->
                    <div class="tab-pane fade" [ngClass]="authType === 'SIGNUP' ? 'show active': '' " id="pills-home"
                        role="tabpanel" aria-labelledby="pills-home-tab">
                        <div class="login-in">
                            <h2>Signup to <b>HouseNow</b></h2>
                            <form [formGroup]="signUpForm" (ngSubmit)="checkExistingAgent()">
                                <div class="form-group mb-1">
                                    <label>Name</label>
                                    <input id="signUpName" type="text" placeholder="Input Name here"
                                        formControlName="signUpName" />
                                </div>
                                <div *ngIf="signUpFormControl['signUpName'] && signUpFormControl['signUpName']">
                                    <div class="text-danger input-error mb-0 fs-7"
                                        *ngIf="signUpFormControl['signUpName'].touched && signUpFormControl['signUpName'].errors && signUpFormControl['signUpName'].errors['required']">
                                        Name is required</div>
                                </div>
                                <div class="form-group mb-1">
                                    <label class="w-100">Phone Number</label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <ngx-mat-intl-tel-input  #intlTelInput [enablePlaceholder]="true" name="signUpNumber"
                                            [onlyCountries]="['gb']"
                                            formControlName="signUpNumber"></ngx-mat-intl-tel-input>
                                    </mat-form-field>

                                    <div class="text-danger input-error mb-0 fs-7"
                                        *ngIf="signUpFormControl['signUpNumber'].touched && signUpFormControl['signUpNumber'].errors?.['required']">
                                        Phone number is required</div>
                                    <div class="text-danger input-error mb-0 fs-7"
                                        *ngIf="signUpFormControl['signUpNumber'].touched && signUpFormControl['signUpNumber'].errors?.['validatePhoneNumber'] && !signUpFormControl['signUpNumber'].errors?.['validatePhoneNumber']?.['valid']">
                                        Invalid Phone number</div>
                                </div>
                                <div class="form-group">
                                    <input type="submit" value="Get OTP" />
                                </div>
                            </form>
                            <div class="or">
                                <span>Or Continue With</span>
                                <hr>
                            </div>
                            <div class="play-store">
                                <a href="#"><img src="assets/images/google.png" alt="" />Google</a>
                                <a href="#"><img src="assets/images/apple.png" alt="" />Apple</a>
                                <a href="#"><img src="assets/images/facebook.png" alt="" />Facebook</a>
                            </div>
                        </div>
                    </div>

                    <!-- LOGIN -->
                    <div class="tab-pane fade" [ngClass]="authType === 'LOGIN' ? 'show active': '' " id="pills-profile"
                        role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div class="login-in">
                            <h2>Signin to <b>HouseNow</b></h2>
                            <form [formGroup]="loginForm" (ngSubmit)="loginAgent()">
                                <div class="form-group w-100 mb-1">
                                    <label class="w-100">Phone</label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <ngx-mat-intl-tel-input #intlTelInput [onlyCountries]="['gb']"
                                            [enablePlaceholder]="true" name="loginNumber"
                                            formControlName="loginNumber"></ngx-mat-intl-tel-input>
                                    </mat-form-field>
                                    <div class="text-danger input-error mb-0 fs-7"
                                        *ngIf="loginFormControl['loginNumber'].touched && loginFormControl['loginNumber'].errors?.['required']">
                                        Phone number is required</div>
                                    <div class="text-danger input-error mb-0 fs-7"
                                        *ngIf="loginFormControl['loginNumber'].touched && loginFormControl['loginNumber'].errors?.['validatePhoneNumber'] && !loginFormControl['loginNumber'].errors?.['validatePhoneNumber']?.['valid']">
                                        Invalid Phone number</div>
                                </div>
                                <div class="form-group mb-1">
                                    <label>Password</label>
                                    <input autocomplete="off" id="loginPassword"
                                        [type]="showPassword ? 'text': 'password'"
                                        placeholder="Input your password here" formControlName="loginPassword" />
                                    <span>
                                        <i (click)="showHidePassword()"
                                            [ngClass]="showPassword ? 'fa fa-eye': 'fa fa-eye-slash' "></i>
                                    </span>
                                </div>
                                <div *ngIf="loginFormControl['loginPassword'] && loginFormControl['loginPassword']">
                                    <div class="text-danger input-error mb-0 fs-7"
                                        *ngIf="loginFormControl['loginPassword'].touched && loginFormControl['loginPassword'].errors && loginFormControl['loginPassword'].errors['required']">
                                        Password is required</div>
                                </div>
                                <div class="form-group forget-ass">
                                    <div class="switch-btn">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch"
                                                id="flexSwitchCheckDefault">
                                            <label class="form-check-label" for="flexSwitchCheckDefault">Remember
                                                me</label>
                                        </div>
                                    </div>
                                    <p (click)="forgetPasswordScreen()" class="forget-password">Forgot Your Password ?
                                    </p>
                                </div>
                                <div class="form-group">
                                    <input type="submit" value="Sign in" />
                                </div>
                            </form>
                            <div class="or">
                                <span>Or Continue With</span>
                                <hr>
                            </div>
                            <div class="play-store">
                                <a href="#"><img src="assets/images/google.png" alt="" />Google</a>
                                <!-- <a href="#"><img src="assets/images/apple.png" alt="" />Apple</a> -->
                                <a href="#"><img src="assets/images/facebook.png" alt="" />Facebook</a>
                            </div>

                        </div>
                    </div>

                    <!-- ADD DETAILS -->
                    <div class="tab-pane fade" [ngClass]="authType === 'ADD_DETAILS' ? 'show active': '' "
                        id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div class="login-in">
                            <h2>Add more details</h2>
                            <form [formGroup]="addDetailsForm" (ngSubmit)="signUpAgent()">
                                <div class="form-group mb-1">
                                    <label>Business Name</label>
                                    <input formControlName="businessName" name="businessName" type="text"
                                        placeholder="Input Business name here" />
                                </div>
                                <div
                                    *ngIf="addDetailsFormControl['businessName'] && addDetailsFormControl['businessName']">
                                    <div class="text-danger input-error mb-0 fs-7"
                                        *ngIf="addDetailsFormControl['businessName'].touched && addDetailsFormControl['businessName'].errors && addDetailsFormControl['businessName'].errors['required']">
                                        Required</div>
                                </div>
                                <div class="form-group mb-1">
                                    <label>License Number</label>
                                    <input formControlName="licenseNumber" name="licenseNumber" type="text"
                                        placeholder="input Licence Number here" />

                                </div>
                                <div
                                    *ngIf="addDetailsFormControl['licenseNumber'] && addDetailsFormControl['licenseNumber']">
                                    <div class="text-danger input-error mb-0 fs-7"
                                        *ngIf="addDetailsFormControl['licenseNumber'].touched && addDetailsFormControl['licenseNumber'].errors && addDetailsFormControl['licenseNumber'].errors['required']">
                                        Required</div>
                                </div>
                                <div class="form-group">
                                    <label>Upload License Images </label>
                                    <p>Upload a clear picture of your license</p>
                                    <div class="outer-uplaod">
                                        <div class="upload-image">
                                            <input type="file" accept="image/*" (change)="uploadImage($event)"
                                                placeholder="input Licence Number here" #uploadLicenseNumber />


                                            <span class="preview-image" *ngIf="licenseImageUrl" (click)="removeImage()">
                                                <img src={{licenseImageUrl}} *ngIf="licenseImageUrl" />
                                                <i class="remove-image fas fa-times"></i>
                                            </span>

                                            <span *ngIf="!licenseImageUrl" class="preview-image"
                                                (click)="uploadLicenseNumber.click()">
                                                <i class="fas fa-plus-circle"></i>
                                            </span>
                                        </div>

                                    </div>
                                    <div class="text-danger input-error mb-0 fs-7"
                                        *ngIf="addDetailsFormControl['licenseImages'].touched && addDetailsFormControl['licenseImages'].errors && addDetailsFormControl['licenseImages'].errors['required']">
                                        Required</div>
                                </div>

                                <div class="form-group mb-1">
                                    <label>Email</label>
                                    <input formControlName="email" type="text" name="email"
                                        placeholder="Enter email here" />
                                </div>
                                <div *ngIf="addDetailsFormControl['email'] && addDetailsFormControl['email']">
                                    <div class="text-danger input-error mb-0 fs-7"
                                        *ngIf="addDetailsFormControl['email'].touched && addDetailsFormControl['email'].errors && addDetailsFormControl['email'].errors['required']">
                                        Required</div>
                                </div>
                                <div *ngIf="addDetailsFormControl['email'] && addDetailsFormControl['email']">
                                    <div class="text-danger input-error mb-0 fs-7"
                                        *ngIf="addDetailsFormControl['email'].touched && addDetailsFormControl['email'].errors && addDetailsFormControl['email'].errors['email']">
                                        Invalid Email</div>
                                </div>

                                <div class="form-group mb-1">
                                    <label>Password</label>
                                    <input autocomplete="off" formControlName="password" type="password" name="password"
                                        placeholder="Enter password here" />
                                </div>
                                <div *ngIf="addDetailsFormControl['password'] && addDetailsFormControl['password']">
                                    <div class="text-danger input-error input-error mb-0 fs-7"
                                        *ngIf="addDetailsFormControl['password'].touched && addDetailsFormControl['password'].errors && addDetailsFormControl['password'].errors['required']">
                                        Required</div>
                                </div>
                                <div class="form-group mb-1">
                                    <label>Confirm Password </label>
                                    <input formControlName="confirmPassword" autocomplete="off" type="password"
                                        name="confirmPassword" placeholder="Enter Confirm password here" />
                                </div>
                                <div class="text-danger input-error mb-0 fs-7"
                                    *ngIf="addDetailsFormControl['confirmPassword'].touched && addDetailsFormControl['confirmPassword'].hasError('mismatch')">
                                    Mismatch password
                                </div>
                                <div class="form-group">
                                    <input data-bs-toggle="modal" data-bs-target="#exampleModal" type="submit"
                                        value="Register" />
                                </div>
                            </form>
                        </div>
                    </div>

                    <!-- FORGET PASSWORD -->
                    <div class="tab-pane fade" [ngClass]="authType === 'FORGET_PASSWORD' ? 'show active': '' "
                        id="pills-home" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div class="login-in">
                            <h2>Forgot Password</h2>
                            <form [formGroup]="forgetPasswordForm" (ngSubmit)="forgetPasswordAgent()">
                                <div class="form-group">
                                    <label>Phone</label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <ngx-mat-intl-tel-input [enablePlaceholder]="true" name="phoneNumber"
                                            [onlyCountries]="['gb']"
                                            formControlName="phoneNumber"></ngx-mat-intl-tel-input>
                                    </mat-form-field>

                                    <div class="text-danger input-error mb-0 fs-7"
                                        *ngIf="forgetPasswordFormControl['phoneNumber'].touched && forgetPasswordFormControl['phoneNumber'].errors?.['required']">
                                        Phone number is required</div>
                                    <div class="text-danger input-error mb-0 fs-7"
                                        *ngIf="forgetPasswordFormControl['phoneNumber'].touched && forgetPasswordFormControl['phoneNumber'].errors?.['validatePhoneNumber'] && !forgetPasswordFormControl['phoneNumber'].errors?.['validatePhoneNumber']?.['valid']">
                                        Invalid Phone number</div>
                                </div>
                                <div class="form-group">

                                    <input type="submit" value="Get OTP" />
                                </div>
                            </form>
                        </div>
                    </div>

                    <!-- VERIFY OTP -->
                    <div class="tab-pane fade" [ngClass]="authType === 'VERIFY_OTP' ? 'show active': '' "
                        id="pills-home" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div class="login-in">
                            <h2 class="text-center">Verification</h2>
                            <form [formGroup]="otpForm" (ngSubmit)="verifyOTP()">
                                <div class="form-group otp-reset d-flex justify-content-center">
                                    <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:6}">
                                    </ng-otp-input>
                                </div>
                                <div class="text-danger input-error mb-0 fs-7 text-center"
                                    *ngIf="otpFormControl['otp'].touched && otpFormControl['otp'].errors?.['required']">
                                    Required
                                </div>
                                <div class="text-danger input-error mb-0 fs-7 text-center"
                                    *ngIf="otpFormControl['otp'].touched && otpFormControl['otp'].errors?.['minlength']">
                                    OTP must be at least 6 characters long.
                                </div>
                                <div class="form-group">
                                    <input type="submit" value="Verify" />
                                </div>
                            </form>
                            <div class="opt-resert-det">
                                <p>Didn’t receive OTP yet?</p>
                                <p *ngIf="timer.minutes <= 0 && timer.seconds <= 0" (click)="generateNewOTP()"
                                    class="resend-otp">Resend OTP</p>
                                <div *ngIf="timer.minutes > 0 || timer.seconds > 0">
                                    <h6>in 0{{ timer.minutes }}:{{ formatTimer(timer.seconds) }} minutes</h6>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- NEW PASSWORD -->
                    <div class="tab-pane fade" [ngClass]="authType === 'NEW_PASSWORD' ? 'show active': '' "
                        id="pills-home" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div class="login-in">
                            <h2>Forgot Password</h2>
                            <form [formGroup]="newPasswordForm" (ngSubmit)="resetNewPassword()">
                                <div class="form-group mb-1">
                                    <label>Password</label>
                                    <input autocomplete="off" formControlName="password" type="password" name="password"
                                        placeholder="Enter password here" />
                                </div>
                                <div *ngIf="newPasswordControl['password'] && newPasswordControl['password']">
                                    <div class="text-danger input-error input-error mb-0 fs-7"
                                        *ngIf="newPasswordControl['password'].touched && newPasswordControl['password'].errors && newPasswordControl['password'].errors['required']">
                                        Password is required</div>
                                </div>
                                <div class="form-group mb-1">
                                    <label>Confirm Password </label>
                                    <input formControlName="confirmPassword" autocomplete="off" type="password"
                                        name="confirmPassword" placeholder="Enter Confirm password here" />
                                </div>
                                <div class="text-danger input-error mb-0 fs-7"
                                    *ngIf="newPasswordControl['confirmPassword'].touched && newPasswordControl['confirmPassword'].hasError('mismatch')">
                                    Mismatch password
                                </div>
                                <div class="form-group">
                                    <input type="submit" value="Change Password" />
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>