export const MESSAGES = {
	INVALID_CREDENTIALS: {
		TITLE: 'Authentication Failed',
		BODY: 'The phone number or password you entered is incorrect. Please check your credentials and try again.'
	},

	SIGNUP_SUCCESSFULL: {
		TITLE: 'Signup',
		BODY: 'Agent created successfully'
	},

	INVALID_PHONE_NO: {
		TITLE: 'Not Found',
		BODY: 'Phone number not found. Please check the entered phone number and try again.'
	},

	PASSWORD_CHANGED_SUCCESS: {
		TITLE: 'Success',
		BODY: 'Your password has been successfully changed. You can now log in with your new password.'
	},

	PROFILE_UPDATE_SUCCESS: 'Profile updated successfully',
	INCORRECT_OTP: 'Incorrect OTP',
	CORRECT_OTP: 'Correct OTP',

	PROPERTY_SUCCESSFULL: {
		TITLE: 'Property',
		BODY: 'Property created successfully'
	},
	LEAD_SUCCESSFULL: {
		TITLE: 'Lead',
		BODY: 'Lead created successfully'
	},
	TASKADDED_SUCCESSFULL:{
		TITLE: 'Calander Task',
		BODY: 'Task created successfully'
	},
	TASK_STATUS_UPDATE:{
		TITLE: 'Status Update',
		BODY: 'Task Status Update successfully'
	}
};


