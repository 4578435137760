// auth.service.ts
import { Injectable } from '@angular/core';
@Injectable({
	providedIn: 'root',
})
export class AuthService {

	constructor() { }
  
	isAuthenticated() {
  
	  const token = localStorage.getItem('accessToken'); // get token from local storage
		if(token){
			return true;
		}else{
			return false;
		}
  
	}
  
  }