<div class="d-flex">
    <sidebar style=""> </sidebar>
    <div class="side-bar-right">
        <div class="top-bar">
            <div class="ham-brhr">
                <span  (click)="addingClass()"><img src="assets/images/hamrgr.png" alt="" /></span>
            </div>
            <div class="search-top">
                <div class="form-group">
                    <input type="text" placeholder="Searching..." />
                    <button type="submit"><img src="assets/images/search.png" alt="" /></button>
                </div>
            </div>
            <div class="night-effect">
                <div [ngClass]="{'active-effect': themeMode === 'BLACK'}" (click)="changeTheme()" class="left-effect">
                    <!-- <img src="assets/images/night-mode.png" alt="" /> -->
                    <i class="fa-regular fa-moon"></i>
                </div>
                <div [ngClass]="{'active-effect': themeMode === 'WHITE'}" (click)="changeTheme()" class="right-effect">
                    <img src="assets/images/day-mode.png" alt="" />
                    <!-- <i class="fa-solid fa-sun-bright"></i> -->
                </div>
            </div> 
            
            <div class="notification-bar">
                <div class="notify">
                    <img src="assets/images/bell.png" alt="" />
                    <span>2</span> 
                </div>
                <div class="agent-name">
                    <span><img [src]="user?.profileImage ? user?.profileImage : 'assets/images/Avatar.png'" alt="" /></span>
                    <div class="nnmelist">
                        <h5>{{ user?.name }}</h5>
                        <span>Agent</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="outtre-pad">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>