import { Injectable } from '@angular/core';
import { HttpService } from './http-service.service';
import { Observable } from 'rxjs';
import { UploadImageResponse } from './interfaces/common.interface';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private readonly httpService: HttpService) { }

  upload(formData: FormData): Observable<UploadImageResponse> {
    return this.httpService.post('common/upload', formData) as Observable<UploadImageResponse>;
  }
}
