import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard {

	constructor(
		private authService: AuthService,
		private router: Router
	) { }

	canActivate(): Promise<boolean | UrlTree> {
		return new Promise((resolve) => {
			if (this.authService.isAuthenticated()) {
				resolve(true);
			} else {

				// resolve(this.router.parseUrl('/login'));
			}
		});
	}
}
