<div class="d-flex justify-content-between align-items-center pe-4">
    <h3 mat-dialog-title>Profile</h3>
    <i (click)="closeDialog()" class="fas fa-times close"></i>
</div>

<form [formGroup]="profileForm" (ngSubmit)="updateProfile()">
    <mat-dialog-content>
        <div class="d-flex flex-column align-items-center mb-3">
            <img [src]="userImageUrl ? userImageUrl : 'assets/images/Avatar.png'" class="user-image" />
            <div class="mt-3 upload-button cursor-pointer" (click)="uploadUserImage.click()">CHANGE</div>
            <input type="file" accept="image/*" (change)="uploadImage($event)" class="d-none" #uploadUserImage />
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group mb-2">
                    <label>Full name</label>
                    <input type="text" autocomplete="off" id="name" name="name" formControlName="name"
                        placeholder="Input your name here" />

                    <div class="text-danger input-error mb-0 fs-7"
                        *ngIf="profileControl['name'].touched && profileControl['name'].errors && profileControl['name'].errors['required']">
                        Full name is required
                    </div>

                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group mb-2">
                    <label>Company Name</label>
                    <input type="text" autocomplete="off" id="companyName" formControlName="companyName"
                        placeholder="Input your Company name here" />

                    <div class="text-danger input-error mb-0 fs-7"
                        *ngIf="profileControl['companyName'].touched && profileControl['companyName'].errors && profileControl['companyName'].errors['required']">
                        Company name is required
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="form-group mb-2">
                    <label>Email</label>
                    <input type="text" autocomplete="off" id="email" formControlName="email"
                        placeholder="Input your Email here" />

                    <div class="text-danger input-error mb-0 fs-7"
                        *ngIf="profileControl['email'].touched && profileControl['email'].errors && profileControl['email'].errors['required']">
                        Email is required
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group mb-2">
                    <label>License number</label>
                    <input type="text" autocomplete="off" id="licenseNumber" name="licenseNumber"
                        formControlName="licenseNumber" placeholder="Input your license number here" />

                    <div class="text-danger input-error mb-0 fs-7"
                        *ngIf="profileControl['licenseNumber'].touched && profileControl['licenseNumber'].errors && profileControl['licenseNumber'].errors['required']">
                        License number is required
                    </div>

                </div>
            </div>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions class="d-flex justify-content-end pe-4">
        <button type="submit">Update</button>
    </mat-dialog-actions>
</form>