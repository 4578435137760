import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { MESSAGES } from 'src/app/core/messages';

import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { Agent, UpdateProfilePayload } from 'src/app/services/interfaces/auth.interface';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent {

  profileForm: FormGroup;
  userProfile?: Agent;
  userImageUrl: string | ArrayBuffer | null = null;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly dialogRef: MatDialogRef<UpdateProfileComponent>,
    private readonly authService: AuthService,
    private readonly commonService: CommonService,
    private readonly notificationService: NotificationService,
  ) {
    this.profileForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      licenseNumber: new FormControl('', [Validators.required]),
      companyName: new FormControl('', [Validators.required]),
      profileImage: new FormControl(null),
    });

    this.authService.user.subscribe((user) => {
      this.profileForm.controls['name'].setValue(user?.name);
      this.profileForm.controls['email'].setValue(user?.email);
      this.profileForm.controls['licenseNumber'].setValue(user?.licenseNumber);
      this.profileForm.controls['companyName'].setValue(user?.businessName);

      if (user?.profileImage) {
        this.userImageUrl = user?.profileImage;
      }
    });
  }

  get profileControl() {
    return this.profileForm.controls;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  async updateProfile() {
    this.profileForm.markAllAsTouched();

    if (!this.profileForm.valid) return;

    const payload: UpdateProfilePayload = {
      name: this.profileForm.value.name,
      email: this.profileForm.value.email,
      licenseNumber: this.profileForm.value.licenseNumber,
    };

    if (this.profileForm.value.profileImage) {
      const formData = new FormData();
      formData.set('file', this.profileForm.value.profileImage);

      const imageResponse = await firstValueFrom(this.commonService.upload(formData));
      payload.profileImage = imageResponse.url;
    }

    this.authService
      .updateProfile(payload)
      .subscribe(
        (res) => {
          this.authService.user.next(res.user);
          this.closeDialog();

          this.notificationService.showSuccess(MESSAGES.PROFILE_UPDATE_SUCCESS);
        },
        (error) => {
          this.notificationService.showError(error.message);
        },
      );
  }

  uploadImage(event: Event): void {
    const inputElement = event.target as HTMLInputElement;

    const file = inputElement.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {

        this.userImageUrl = reader.result as string;
        inputElement.value = '';

        this.profileForm.patchValue({
          profileImage: file,
        });
      };
    }
  }
}
