import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LEAD_REQUIREMENT_STATUS } from 'src/app/core/constants/properties';
import { MESSAGES } from 'src/app/core/messages';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-lead',
  templateUrl: './add-lead.component.html',
  styleUrls: ['./add-lead.component.css']
})
export class AddLeadComponent implements OnInit {

  leadForm: FormGroup;
  types: any = LEAD_REQUIREMENT_STATUS
  properties: any;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly apiService: ApiService,
    private readonly router: Router,
    private readonly dialogRef: MatDialogRef<AddLeadComponent>
  ) {
    this.leadForm = this.formBuilder.group({
      propertyID: new FormControl('', [Validators.required]),
      fullName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      location: new FormControl('', [Validators.required]),
      requirements: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required]),
    });

  }
  ngOnInit(): void {
    this.propertyList()
    
  }

  closeDialog() {
    this.dialogRef.close();
  }

  get leadControl() {
    return this.leadForm.controls;
  }

  createLead() {
    if (this.leadForm.invalid) {
      console.log(this.leadForm);

      this.leadForm.markAllAsTouched();
      return
    }
    const payload = {
      propertyId: this.leadForm.value.propertyID,
      enquiryText:this.leadForm.value.requirements,
      userDetails: {
        fullName: this.leadForm.value.fullName,
        email: this.leadForm.value.email,
        address: this.leadForm.value.address,
        location: this.leadForm.value.location,
        phone: this.leadForm.value.phoneNumber,
        state: this.leadForm.value.state,
        city: this.leadForm.value.city,
        requirements: this.leadForm.value.requirements
      }
    }
    this.apiService.createLead(payload).subscribe((resp: any) => {
      Swal.fire({ title: MESSAGES.LEAD_SUCCESSFULL.TITLE, text: MESSAGES.LEAD_SUCCESSFULL.BODY, icon: 'success' });
      this.closeDialog()


    })

  }
  propertyList(data = null): void {
    console.log(data);
    this.apiService.properties(data);
    this.apiService.poperty.subscribe((property) => {
      this.properties = property?.[0].data ?? [];


    });

  }
  

}
