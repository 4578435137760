import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MESSAGES } from 'src/app/core/messages';

import { AuthService } from 'src/app/services/auth.service';
import { Agent } from 'src/app/services/interfaces/auth.interface';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent {

  resetPasswordForm: FormGroup;
  userProfile?: Agent;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly dialogRef: MatDialogRef<ResetPasswordComponent>,
    private readonly authService: AuthService,
    private readonly notificationService: NotificationService,
  ) {
    this.resetPasswordForm = this.formBuilder.group({
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    }, { validator: this.passwordMatchValidator });

    this.authService.user.subscribe((user) => this.userProfile = user as Agent);
  }

  private passwordMatchValidator(form: FormGroup) {
    const passwordControl = form.get('password');
    const confirmPasswordControl = form.get('confirmPassword');

    if (passwordControl?.value === confirmPasswordControl?.value) {
      return null;
    } else {
      confirmPasswordControl?.setErrors({ mismatch: true });
      return { mismatch: true };
    }
  }

  get resetPasswordControl() {
    return this.resetPasswordForm.controls;
  }

  resetPassword() {
    this.resetPasswordForm.markAllAsTouched();

    if (!this.resetPasswordForm.valid) return;

    const payload = {
      agentId: this.userProfile?._id as string,
      password: this.resetPasswordForm.value.password,
    };

    this.authService.resetPassword(payload).subscribe(res => {
      this.notificationService.showSuccess(MESSAGES.PROFILE_UPDATE_SUCCESS);

      this.closeDialog();
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
