import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccessControlComponent } from './modules/access-control/access-control.component';
import { AuthGuard } from './core/guards/auth.guards';
import { LoggedInGuard } from './core/guards/logged-in.guard';

const routes: Routes = [
  { path: '', canActivate: [LoggedInGuard], component: AccessControlComponent },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
