
const PROPERTY_STATUS = {
	PENDING: "PENDING",
	DECLINED: "DECLINED",
	APPROVED: "APPROVED"
}

const PROPERTY_TYPE = [
	"COMMERCIAL", "RESIDENTIAL"
]

const PROPERTY_AVAILABILITY = [
	"SOLD", "AVAILABLE"
]

const PROPERTY_AVAILABILITY_STATUS = {
	RESERVED: "RESERVED",
	AVAILABLE: "AVAILABLE",
	BOOKED: "BOOKED"
}

const PROPERTY_SELLING_TYPE = [
	"RENT", "MORTGAGE", "AUCTION", "SELLING"
]


const PROPERTY_RENT_TYPE = {
	WEEKLY: "WEEKLY",
	MONTHLY: "MONTHLY",
	YEARLY: "YEARLY"
}

const PROPERTY_MEASUREMENT_UNIT = [
	"Square meters (m²)", "Square feet (ft²)",
	"Square kilometers (km²)",
	"Acres"
]
const LEAD_REQUIREMENT_STATUS = [
	"SALE",
    "RENT"
]


export {
	PROPERTY_STATUS,
	PROPERTY_TYPE,
	PROPERTY_AVAILABILITY,
	PROPERTY_AVAILABILITY_STATUS,
	PROPERTY_SELLING_TYPE,
	PROPERTY_RENT_TYPE,
	PROPERTY_MEASUREMENT_UNIT,
	LEAD_REQUIREMENT_STATUS

};