import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class LoggedInGuard {
	constructor(private authService: AuthService, private router: Router) { }

	canActivate(): Promise<boolean | UrlTree> {
		return new Promise((resolve) => {
			if (this.authService.isAuthenticated()) {
				this.router.navigate(['/dashboard']);
				resolve(false);
			} else {
				resolve(true);
			}
		});
	}

}
