import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';

import { HttpService } from './http-service.service';
import {
	CategoryResponse,
	CreatePropertyPayload,
	PropertyResponse
} from './interfaces/api.interface';
import { HttpParams } from '@angular/common/http';


@Injectable({
	providedIn: 'root'
})
export class ApiService {

	constructor(private readonly httpService: HttpService) { }

	poperty: BehaviorSubject<PropertyResponse | null> = new BehaviorSubject<PropertyResponse | null>(null);
	category: BehaviorSubject<CategoryResponse | null> = new BehaviorSubject<CategoryResponse | null>(null);

	generateQueryParam(data: any) {
		let queryParams = new HttpParams();
		if (data) {

			for (var key in data) {
				queryParams = queryParams.append(key, data[key]);

			}
			return queryParams
		}
	}

	async properties(data = null) {
		let queryString = data ?this.generateQueryParam(data) : "";
		const response: any = await firstValueFrom(this.httpService.get('property?' + queryString)) as PropertyResponse;
		this.poperty.next(response.properties);
	}

	async propertyDetail(data: any | undefined) {
		const queryParams = this.generateQueryParam(data)
		const response: any = await firstValueFrom(this.httpService.get('property?' + queryParams)) as PropertyResponse;
		this.poperty.next(response.properties);
	}

	createProperty(payload: CreatePropertyPayload) {
		return this.httpService.post('property', payload);
	}


	async categories() {
		const response: any = await firstValueFrom(this.httpService.get('category')) as CategoryResponse;
		this.category.next(response.categories);
	}
	createLead(payload: any) {
		return this.httpService.post('lead', payload);
	}

	getLead(data: any = null) {
		const queryParams = this.generateQueryParam(data)

		return this.httpService.get('lead?' + queryParams)
	}

	getSpecificLead(id: any) {
		return this.httpService.get('lead?leadId=' + id)
	}
	addCalanderTask(data: any) {
		
		return this.httpService.post('task', data)
	}

	getAllCalanderTasks(data:any) {
		const queryParams = this.generateQueryParam(data)
		return this.httpService.get('task?'+queryParams)
	}
	updateTaskStatus(id: any, data: any) {
		return this.httpService.patch('task/' + id, data)
	}

}
