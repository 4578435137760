import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import libPhoneNumber from 'libphonenumber-js';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NgOtpInputComponent } from 'ng-otp-input';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { firstValueFrom } from 'rxjs';

import { AUTH_TYPE, LOCAL_STORAGE, STATUS_CODE } from '../../core/constants';
import { AuthService } from 'src/app/services/auth.service';
import { OTPTimer } from 'src/app/services/interfaces/auth.interface';
import { MESSAGES } from 'src/app/core/messages';
import { CommonService } from 'src/app/services/common.service';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
	selector: 'app-access-control',
	templateUrl: './access-control.component.html',
	styleUrls: ['./access-control.component.css']
})
export class AccessControlComponent {
	authType: string = AUTH_TYPE.LOGIN;

	timer: OTPTimer = { minutes: 0, seconds: 0 };
	timerInterval?: any;

	loginForm: FormGroup;
	signUpForm: FormGroup;
	addDetailsForm: FormGroup;
	forgetPasswordForm: FormGroup;
	otpForm: FormGroup;
	newPasswordForm: FormGroup;

	separateDialCode = false;
	licenseImageUrl: string | ArrayBuffer | null = null;
	showPassword: boolean = false;
	agentId?: string;

	@ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput?: NgOtpInputComponent;
	@ViewChild('intlTelInput') intlTelInput?: NgxMatIntlTelInputComponent;
	@ViewChild('uploadLicenseNumber') uploadLicenseNumber?: ElementRef;

	constructor(
		private formBuilder: FormBuilder,
		private readonly router: Router,
		private readonly authService: AuthService,
		private readonly commonService: CommonService,
		private readonly notificationService: NotificationService,
	) {
		this.loginForm = this.formBuilder.group({
			loginNumber: new FormControl('', [Validators.required]),
			loginPassword: new FormControl('', [Validators.required])
		});

		this.signUpForm = this.formBuilder.group({
			signUpName: new FormControl('', [Validators.required]),
			signUpNumber: new FormControl('', [Validators.required]),
			countryCode: new FormControl(''),
		});

		this.addDetailsForm = this.formBuilder.group({
			businessName: new FormControl('', [Validators.required]),
			licenseNumber: new FormControl('', [Validators.required]),
			email: new FormControl('', [Validators.email, Validators.required]),
			password: new FormControl('', [Validators.required]),
			confirmPassword: new FormControl('', [Validators.required]),
			licenseImages: new FormControl('', [Validators.required])
		}, { validator: this.passwordMatchValidator });

		this.forgetPasswordForm = this.formBuilder.group({
			phoneNumber: new FormControl('', [Validators.required]),
			countryCode: new FormControl(''),
		});

		this.newPasswordForm = this.formBuilder.group({
			password: new FormControl('', [Validators.required]),
			confirmPassword: new FormControl('', [Validators.required]),
		}, { validator: this.passwordMatchValidator });

		this.otpForm = this.formBuilder.group({
			otp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
		});
	}

	private passwordMatchValidator(form: FormGroup) {
		const passwordControl = form.get('password');
		const confirmPasswordControl = form.get('confirmPassword');

		if (passwordControl?.value === confirmPasswordControl?.value) {
			return null;
		} else {
			confirmPasswordControl?.setErrors({ mismatch: true });
			return { mismatch: true };
		}
	}

	get loginFormControl() {
		return this.loginForm.controls;
	}

	get signUpFormControl() {
		return this.signUpForm.controls;
	}

	get addDetailsFormControl() {
		return this.addDetailsForm.controls;
	}

	get forgetPasswordFormControl() {
		return this.forgetPasswordForm.controls;
	}

	get otpFormControl() {
		return this.otpForm.controls;
	}

	get newPasswordControl() {
		return this.newPasswordForm.controls;
	}

	formatTimer(value: number): string {
		return value < 10 ? `0${value}` : value.toString();
	}

	showHidePassword(): void {
		this.showPassword = !this.showPassword;
	}

	handleAuthChange(type: string): void {
		this.authType = type;

		this.signUpForm.reset();
		this.loginForm.reset();
		this.forgetPasswordForm.reset();
		this.otpForm.reset();
		this.addDetailsForm.reset();

		this.intlTelInput?.reset();
	}


	handleAutoLogin(res: any): void {
		localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, res.user.accessToken);
		this.router.navigate(['/dashboard']);
	}

	handleLoginAgent(payload: any) {
		const payloadN = {
			phoneNumber: payload?.phoneNumber,
			countryCode: payload?.countryCode,
			password: payload?.password,
		};

		this.authService.login(payloadN)
			.subscribe(
				(res) => {
					this.intlTelInput?.reset();

					this.handleAutoLogin(res);
				},
				(error) => {
					if (error.statusCode === STATUS_CODE.BAD_REQUEST) {

						Swal.fire({
							title: MESSAGES.INVALID_CREDENTIALS.TITLE,
							text: MESSAGES.INVALID_CREDENTIALS.BODY,
							icon: 'error'
						});
					}
				},
			);
	}

	loginAgent(): void {
		this.loginForm.markAllAsTouched();

		if (!this.loginForm.valid) return;

		const phoneNumber = libPhoneNumber(this.loginForm.value.loginNumber);

		const payload = {
			phoneNumber: phoneNumber?.nationalNumber as string,
			countryCode: phoneNumber?.countryCallingCode as string,
			password: this.loginForm.value.loginPassword,
		};

		this.handleLoginAgent(payload);
	}

	resetNewPassword(): void {
		this.newPasswordForm.markAllAsTouched();

		if (!this.newPasswordForm.valid) return;

		const payload = {
			password: this.newPasswordForm.value.password,
			agentId: this.agentId as string,
		};

		this.authService.resetPassword(payload).subscribe(
			(res) => {
				Swal.fire({
					title: MESSAGES.PASSWORD_CHANGED_SUCCESS.TITLE,
					text: MESSAGES.PASSWORD_CHANGED_SUCCESS.BODY,
					icon: 'success'
				});

				this.newPasswordForm.reset();

				this.authType = AUTH_TYPE.LOGIN;
			},
		)
	}

	checkExistingAgent(): void {
		this.signUpForm.markAllAsTouched();

		if (!this.signUpForm.valid) return;

		const phoneNumber = libPhoneNumber(this.signUpForm.value.signUpNumber);

		if (phoneNumber) {
			const payload = {
				phoneNumber: phoneNumber.nationalNumber,
				countryCode: phoneNumber.countryCallingCode,
			};

			this.authService.verifyPhone(payload)
				.subscribe(
					(res) => {
						if (res.statusCode === STATUS_CODE.SUCCESS) {
							this.authType = AUTH_TYPE.VERIFY_OTP;

							this.startTimer();
						}
					},
					(error) => {
						if (error.statusCode === STATUS_CODE.CONFLICTING_RESOURCE) {
							Swal.fire({ title: 'Phone number', text: error.message, icon: 'error' });
						}
					},
				);
		}
	}

	startTimer(): void {
		this.timer = { minutes: 2, seconds: 0 };

		this.timerInterval = setInterval(() => {
			if (this.timer.minutes > 0 || this.timer.seconds > 0) {
				if (this.timer.seconds === 0) {
					this.timer.minutes--;
					this.timer.seconds = 59;
				} else {
					this.timer.seconds--;
				}
			} else {
				clearInterval(this.timerInterval);
			}
		}, 1000);
	}

	async signUpAgent(): Promise<void> {
		this.addDetailsForm.markAllAsTouched();

		if (!this.addDetailsForm.valid) return;

		const phoneNumber = libPhoneNumber(this.signUpForm.value.signUpNumber);

		const agentFormData = new FormData();

		agentFormData.append('file', this.addDetailsForm.value.licenseImages);

		const imageResponse = await firstValueFrom(this.commonService.upload(agentFormData));

		const payload = {
			name: this.signUpForm.value.signUpName,
			licenseNumber: this.addDetailsForm.value.licenseNumber,
			email: this.addDetailsForm.value.email,
			password: this.addDetailsForm.value.password,
			businessName: this.addDetailsForm.value.businessName,
			licenseImages: [imageResponse.url],
			phoneNumber: phoneNumber?.nationalNumber as string,
			countryCode: phoneNumber?.countryCallingCode as string,
		};

		this.authService.createAgent(payload)
			.subscribe(
				(res) => {
					Swal.fire({ title: MESSAGES.SIGNUP_SUCCESSFULL.TITLE, text: MESSAGES.SIGNUP_SUCCESSFULL.BODY, icon: 'success' });
					this.handleAuthChange(AUTH_TYPE.LOGIN);
					this.handleLoginAgent(payload);
				},
			)
	}

	forgetPasswordAgent(): void {
		this.forgetPasswordForm.markAllAsTouched();

		if (!this.forgetPasswordForm.valid) return;

		const phoneNumber = libPhoneNumber(this.forgetPasswordForm.value.phoneNumber);

		const payload = {
			phoneNumber: phoneNumber?.nationalNumber as string,
			countryCode: phoneNumber?.countryCallingCode as string,
		};

		this.authService.forgotPassword(payload)
			.subscribe(
				() => {
					this.authType = AUTH_TYPE.VERIFY_OTP;
				},
				(error) => {
					if (error.statusCode === STATUS_CODE.NOT_FOUND) {
						Swal.fire({
							title: MESSAGES.INVALID_PHONE_NO.TITLE,
							text: MESSAGES.INVALID_PHONE_NO.BODY,
							icon: 'error'
						});
					}
				},
			);
	}

	verifyOTP(): void {
		this.otpForm.markAllAsTouched();

		if (!this.otpForm.valid) return;

		const phoneNumber = libPhoneNumber(
			this.forgetPasswordForm.value.phoneNumber && this.forgetPasswordForm.value.phoneNumber.length ? this.forgetPasswordForm.value.phoneNumber : this.signUpForm.value.signUpNumber
		);

		const payload = {
			phoneNumber: phoneNumber?.nationalNumber as string,
			countryCode: phoneNumber?.countryCallingCode as string,
			otp: this.otpForm.value.otp,
			flag: this.forgetPasswordForm.value.phoneNumber && this.forgetPasswordForm.value.phoneNumber.length ? 2 : 1,
		};

		this.authService.verifyOTP(payload)
			.subscribe(
				(res) => {
					if (payload.flag === 1) {

						this.authType = AUTH_TYPE.ADD_DETAILS;
					}
					else {
						this.authType = AUTH_TYPE.NEW_PASSWORD;
						this.agentId = res.agentId;
					};

					this.ngOtpInput?.setValue('');

					this.notificationService.showSuccess(MESSAGES.CORRECT_OTP);

				},
				(error) => {
					this.notificationService.showError(MESSAGES.INCORRECT_OTP);
				},
			);
	}

	onOtpChange(value: string): void {
		this.otpForm.setValue({ otp: value });
	}

	generateNewOTP(): void {

		const phoneNumber = libPhoneNumber(this.authType === AUTH_TYPE.FORGET_PASSWORD ? this.forgetPasswordForm.value.phoneNumber : this.signUpForm.value.signUpNumber);

		const payload = {
			phoneNumber: phoneNumber?.nationalNumber as string,
			countryCode: phoneNumber?.countryCallingCode as string,
		};

		this.authService.verifyPhone(payload)
			.subscribe(() => {
				this.startTimer();
			});
	}

	uploadImage(event: Event): void {
		const inputElement = event.target as HTMLInputElement;

		const file = inputElement.files?.[0];

		if (file) {
			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = () => {

				this.licenseImageUrl = reader.result as string;
				inputElement.value = '';

				this.addDetailsForm.patchValue({
					licenseImages: file,
				});
			};
		}
	}

	removeImage(): void {
		this.licenseImageUrl = null;
	}

	forgetPasswordScreen(): void {
		this.authType = AUTH_TYPE.FORGET_PASSWORD;
	};
}
