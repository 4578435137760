<div class=" AddleadModal AaaTask_calender" id="Addtask_Modal" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" (click)=" close();"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <h5>Create a Task</h5>
                    <p>As a real estate agent, your primary responsibility is to assist clients in buying or selling
                        properties</p>
                </div>
                <div class="addleadForm">
                    <form [formGroup]="taskForm" (ngSubmit)="addTask()">
                        <div class="form-group">
                            <input type="text" name="Name" formControlName="title" placeholder="Create  title and time">
                            <div class="text-danger input-error mb-0 fs-7"
                                *ngIf="taskControl['title'].touched && taskControl['title'].errors && taskControl['title'].errors['required']">
                                {{'this title is required' }}

                            </div>
                        </div>
                        <div class="form-group">
                            <select formControlName="propertyId" class="form-control" (change)="getLeads()">
                                <option selected disabled value="">select property</option>
                                <option *ngFor="let property of allProperties; index as i;" value="{{property._id}}">
                                    {{property.name}}</option>
                            </select>
                            <div class="text-danger input-error mb-0 fs-7"
                                *ngIf="taskControl['propertyId'].touched && taskControl['propertyId'].errors && taskControl['propertyId'].errors['required']">
                                {{ 'this property is required ' }}
                            </div>
                        </div>
                        <div class="form-group">
                            <select formControlName="leadId" class="form-control">
                                <option selected disabled value="">select lead</option>
                                <option *ngFor="let lead of allLeads index as i;" value="{{lead._id}}">
                                    {{lead.userDetails.fullName}}</option>
                            </select>
                            <div class="text-danger input-error mb-0 fs-7"
                                *ngIf="taskControl['leadId'].touched && taskControl['leadId'].errors && taskControl['leadId'].errors['required']">
                                {{'this lead is required '}}
                            </div>
                        </div>  
                        <div class="form-group">
                            <div class="row align-items-center addTime">
                                <div class="col-9">
                                    <div class="d-flex align-items-center">
                                        <img src="../../../assets/images/time.png">
                                        <div class="text">
                                            <span>{{taskControl['startDate'].value|date:'mediumDate'}} - {{taskControl['startDate'].value|date:'mediumDate'}} </span>
                                            <p class="mb-0">Does not repeat</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="cta">
                                        <a href="javascript:void" (click)="picker.open()" title="">Add Time</a>
                                        <mat-form-field class="ctaPopup" appearance="fill">
                                            <mat-label>Enter a date range</mat-label>
                                            <mat-date-range-input [rangePicker]="picker">
                                                <input matStartDate  formControlName="startDate" placeholder="Start date">
                                                <input matEndDate formControlName="endDate" placeholder="End date">
                                            </mat-date-range-input>
                                            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-date-range-picker #picker></mat-date-range-picker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="text" name="Location" formControlName="location" placeholder="Location">
                            <div class="text-danger input-error mb-0 fs-7"
                                *ngIf="taskControl['location'].touched && taskControl['location'].errors && taskControl['location'].errors['required']">
                                {{ 'this location is required ' }}
                            </div>
                        </div>
                        <div class="form-cta">
                            <input type="Submit" name="Save" value="Save">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>