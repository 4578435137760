<div class="d-flex justify-content-between align-items-center pe-4">
    <h3 mat-dialog-title>Reset Password</h3>
    <i (click)="closeDialog()" class="fas fa-times close"></i>
</div>
<form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
    <mat-dialog-content>
        <div class="form-group mb-2">
            <label>New Password</label>
            <input type="password" autocomplete="off" id="newPassword" name="password" formControlName="password"
                placeholder="Input your password here" />

            <div class="text-danger input-error mb-0 fs-7"
                *ngIf="resetPasswordControl['password'].touched && resetPasswordControl['password'].errors && resetPasswordControl['password'].errors['required']">
                Password is required
            </div>

        </div>
        <div class="form-group mb-2">
            <label>Confirm Password</label>
            <input type="password" autocomplete="off" id="confirmPassword" formControlName="confirmPassword" placeholder="Re enter your password here" />

            <div class="text-danger input-error mb-0 fs-7"
                *ngIf="resetPasswordControl['confirmPassword'].touched && resetPasswordControl['confirmPassword'].hasError('mismatch')">
                Mismatch password
            </div>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions class="d-flex justify-content-end pe-4">
        <button type="submit">Update</button>
    </mat-dialog-actions>
</form>