import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {

  activeUrl: string = '';

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) this.activeUrl = ev.url;
    })
  }

  logoutAgent() {
    // console.log('trigger logout');
    this.router.navigate(['/']);
    localStorage.clear();
  }
}
