import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { AddLeadComponent } from './add-lead/add-lead.component';
import { AddCalanderTaskComponent } from './add-calander-task/add-calander-task.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field'


@NgModule({
  declarations: [
    ResetPasswordComponent,
    UpdateProfileComponent,
    AddLeadComponent,
    AddCalanderTaskComponent
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class ModalsModule { }
