import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { THEME_MODE } from 'src/app/core/constants';
import { AuthService } from 'src/app/services/auth.service';
import { Agent } from 'src/app/services/interfaces/auth.interface';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent {
    constructor(
        private readonly authService: AuthService,
        private renderer: Renderer2,
       private changedetection:ChangeDetectorRef
    ) { }

    themeMode: string = THEME_MODE.WHITE;

    user?: Agent;
    sidebar='open'

    ngOnInit(): void {
        //  this.renderer.addClass(document.body, 'openSidebar');
        this.authService.profile();

        this.authService.user.subscribe((user) => this.user = user as Agent);
    }


    changeTheme(): void { 
        this.themeMode = this.themeMode === THEME_MODE.WHITE ? THEME_MODE.BLACK: THEME_MODE.WHITE;
        if(this.themeMode=='BLACK'){
            this.renderer.addClass(document.body, 'dark');
        }else{
            this.renderer.removeClass(document.body,'dark')
        }
    }
    addingClass(){
       
        if(this.sidebar!='open')
        {
            this.renderer.addClass(document.body, 'openSidebar');
            this.sidebar='open'
        }else{
            this.renderer.removeClass(document.body, 'openSidebar');
            this.sidebar='close'
        }
       this.changedetection.detectChanges()

    }

}
