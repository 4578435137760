<div class="d-flex justify-content-end pt-4 pe-4">
    <i (click)="closeDialog()" class="fas fa-times close"></i>
</div>

<div class="d-flex mt-4 flex-column justify-content-center align-items-center">
    <p class="heading">Add a Lead</p>
    <p class="mb-0 description">As a real estate agent, your primary responsibility is to assist clients in buying or
        selling properties
    </p>
</div>

<form [formGroup]="leadForm" (ngSubmit)="createLead()">
    <div mat-dialog-content> 
        <div class="w-100 mt-4">
            <div class="mb-2"> 
                <label>Property</label>

                <select formControlName="propertyID" class="form-control">
                    <option selected disabled value="" >Select Property</option>
                    <option *ngFor="let propertie of properties; index as i;" [value]="propertie._id">
                        {{propertie.name}}</option>
                </select>
                <div class="text-danger input-error mb-0 fs-7"
                    *ngIf="leadControl['propertyID'].touched && leadControl['propertyID'].errors && leadControl['propertyID'].errors['required']">
                    Required
                </div>
            </div>
            <div class="mb-2">
                <label>Full name</label>
                <input type="text" formControlName="fullName" />
                <div class="text-danger input-error mb-0 fs-7"
                    *ngIf="leadControl['fullName'].touched && leadControl['fullName'].errors && leadControl['fullName'].errors['required']">
                    Required
                </div>
            </div>

            <div class="mb-2">
                <label>Email</label>
                <input type="text" formControlName="email" />
                <div class="text-danger input-error mb-0 fs-7"
                    *ngIf="leadControl['email'].touched && leadControl['email'].errors && leadControl['email'].errors['required']">
                    Required
                </div>
                <div class="text-danger input-error mb-0 fs-7"
                    *ngIf="leadControl['email'].touched && leadControl['email'].errors && leadControl['email'].errors['pattern']">
                    Invalid email
                </div>
            </div>

            <div class="mb-2">
                <label>Phone</label>
                <input type="number" formControlName="phoneNumber" />
                <div class="text-danger input-error mb-0 fs-7"
                    *ngIf="leadControl['phoneNumber'].touched && leadControl['phoneNumber'].errors && leadControl['phoneNumber'].errors['required']">
                    Required
                </div>
            </div>

            <div class="mb-2">
                <label>Address</label>
                <input type="text" formControlName="address" />
                <div class="text-danger input-error mb-0 fs-7"
                    *ngIf="leadControl['address'].touched && leadControl['address'].errors && leadControl['address'].errors['required']">
                    Required
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="mb-2">
                        <label>City</label>
                        <input type="text" formControlName="city" />
                        <div class="text-danger input-error mb-0 fs-7"
                            *ngIf="leadControl['city'].touched && leadControl['city'].errors && leadControl['city'].errors['required']">
                            Required
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-2">
                        <label>State</label>
                        <input type="text" formControlName="state" />
                        <div class="text-danger input-error mb-0 fs-7"
                            *ngIf="leadControl['state'].touched && leadControl['state'].errors && leadControl['state'].errors['required']">
                            Required
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-2">
                <label>Location</label>
                <input type="text" formControlName="location" />
                <div class="text-danger input-error mb-0 fs-7"
                    *ngIf="leadControl['location'].touched && leadControl['location'].errors && leadControl['location'].errors['required']">
                    Required
                </div>
            </div>

            <div class="mb-2">
                <label>Requirements</label>
                <textarea formControlName="requirements"></textarea>
                <div class="text-danger input-error mb-0 fs-7"
                    *ngIf="leadControl['requirements'].touched && leadControl['requirements'].errors && leadControl['requirements'].errors['required']">
                    Required
                </div>
            </div>
            <!-- <div class="mb-2">
                <label>Requirements</label>

                <select formControlName="requirements" class="form-control">
                    <option *ngFor="let type of types; index as i;" value="{{type}}">
                        {{type}}</option>
                </select>
                <div class="text-danger input-error mb-0 fs-7"
                    *ngIf="leadControl['requirements'].touched && leadControl['requirements'].errors && leadControl['requirements'].errors['required']">
                    Required
                </div>
            </div> -->
        </div>
    </div>

    <div mat-dialog-actions class="d-flex justify-content-end">
        <button>Create</button>
    </div>
</form>