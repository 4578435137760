import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EventApi } from '@fullcalendar/core';
import { MESSAGES } from 'src/app/core/messages';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';
import { AddLeadComponent } from '../add-lead/add-lead.component';
@Component({
  selector: 'app-add-calander-task',
  templateUrl: './add-calander-task.component.html',
  styleUrls: ['./add-calander-task.component.css']
})
export class AddCalanderTaskComponent implements OnInit {
  taskForm!: FormGroup
  currentEvents: EventApi[] = [];
  allProperties: any = []
  allLeads: any = []
 
  constructor(private fb: FormBuilder, private readonly apiService: ApiService,private readonly dialogRef: MatDialogRef<AddLeadComponent>) {
    this.taskForm = this.fb.group({
      title: ['', Validators.required],
      propertyId: ['', Validators.required],
      leadId: ['', Validators.required],
      startDate: [''],
      endDate: [''],
      location: ['']

    })
  }
  get taskControl() {
    return this.taskForm.controls;
  }
  ngOnInit(): void {
    this.propertyList()
  }

  propertyList(data = null): void {
    console.log(data);
    this.apiService.properties(data);
    this.apiService.poperty.subscribe((property) => {
      this.allProperties = property?.[0].data ?? [];
    });

  }
  getLeads() {
    this.apiService.getLead({ propertyId: this.taskForm.controls['propertyId'].value }).subscribe((resp: any) => {
      this.allLeads = resp.properties?.[0].data ?? [];

    })
  }

  addTask() {
    if (this.taskForm.invalid) {
      this.taskForm.markAllAsTouched()
      return
    }
    const data = {
      "propertyId": this.taskControl['propertyId'].value,
      "title":this.taskControl['title'].value,
      "leadId": this.taskControl['leadId'].value,
      "endDate": this.taskControl['endDate'].value,
      "startDate":  this.taskControl['startDate'].value ,
      "location": []
    }
    console.log("task form ==>", this.taskForm.value);
    this.apiService.addCalanderTask(data).subscribe((resp:any)=>{
     if(resp.statusCode==200){
      Swal.fire({ title: MESSAGES.TASKADDED_SUCCESSFULL.TITLE, text: MESSAGES.TASKADDED_SUCCESSFULL.BODY, icon: 'success' });
      this.dialogRef.close();

     }
      
    })
  }
  close(){
    this.dialogRef.close();
  }
}
